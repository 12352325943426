@import "../../resources/styles/media";

.container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.content {
    box-shadow: none;
    background-color: transparent;
    width: 100%;
    max-width: none;
    margin: 0;
    position: relative;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    @media (max-width: @screen-sm) {
        margin: 0.8rem;
    }
}

.consumerContent {
    flex: 1;
}
